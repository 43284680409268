<template>
	<v-sheet>
			<user-list-tab 
				:role="activeRole"
				:status="status"
				:includeMeta="1"
				:includeWorkTeams="1"
				:includeProjects="1"
				@on-filter-role="onFilterRole($event)"
				@on-filter-status="onFilterStatus($event)">
				<template v-slot="{ item }">
					<v-btn 
						color="primary"
						elevation="0"
						small
						class="mr-1"
						:to="{ name: 'viewAccount', params: {id:item.id}}"
					>
						<v-icon small left>
							mdi-pencil
						</v-icon>
						编辑
					</v-btn>
					<v-btn 
						color="info"
						small
						elevation="0"
						class="mr-2"
						@click="onWorkerPreview(item.id)">
						<v-icon small left>
							mdi-eye
						</v-icon>
						查看</v-btn>
				</template>
			</user-list-tab>
		<v-divider></v-divider>
		<v-dialog v-model="previewDialog" max-width="680">
			<v-card v-if="selectedWorkerId">
				<v-card-title>查看工人信息</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<account-preview :workerId="selectedWorkerId" />
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>

<script>
import UserListTab from '../../components/User/UserListTab.vue';
import AccountPreview from './account_preview.vue';

import { mapState } from 'vuex';

export default {
	name: 'account',
	components: {
		UserListTab,
		AccountPreview
	},
	computed: {
		...mapState('user', ['user'],),
	},
	data: () => ({
		tab: null,
		users: [],
		activeRole: 'worker,contractor,team-leader,site-manager,admin',
		itemsPerPage: 25,
		totalPages: 1,
		currentPage: 1,
		items: [5, 10, 15, 25],
		status: 0,
		loading: false,
		selectedWorkerId: null,
		previewDialog: false
	}),
	watch: {
		previewDialog(){
			if (this.previewDialog === false ){
				this.selectedWorkerId = null
			}
		}
	},
	methods: {

		onFilterRole(roleName){
			this.activeRole = roleName;
		},
		onFilterStatus(status){
			this.status = status;
		},
		onWorkerPreview(id) {
			this.selectedWorkerId = id;
			this.previewDialog = true;
		}
	},
	created(){
		this.loading = true;
	}
}
</script>
