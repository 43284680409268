<!-- HTML for list of workers and how to find and filter the user you need. -->
<template>
	<v-sheet>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-data-table
					:headers="headers"
					:items="users"
					:items-per-page="itemsPerPage"
					:page.sync="currentPage"
					@update:options="onUpdatePageOptions"
					:footer-props="{
						'items-per-page-text': '每页显示',
						'items-per-page-options': items
					}"
				>
					<template v-slot:top>
						<div class="d-flex align-center">
							<v-text-field
							dense
							outlined
							v-model="search"
							label="搜索"
							@input="onUserSearch"
							prepend-inner-icon="mdi-magnify"
							clearable
							clear-icon="mdi-close"
							hide-details
							placeholder="您可以通过用户名，e-mail和电话号码进行用户搜索"
							@click:clear="clearUserSearch"
							class="mx-1 my-2"
							></v-text-field>
							<v-spacer></v-spacer>
							<v-divider vertical class="mx-1"></v-divider>
							<v-menu offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
									color="primary"
									text
									small
									class="ma-1 mx-1"
									v-bind="attrs"
									v-on="on"
									>
										<v-icon left>mdi-account-filter-outline</v-icon>
										<span class="text-capitalize">显示: {{ activeRole | roleType }}</span>
										<v-icon right>mdi-chevron-down</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<template v-for="(role, index) in roles">
									<v-list-item
										:key="index"
										@click="onRoleChange(role.name)"
										>
										<v-list-item-title >{{ role.name | roleType }}</v-list-item-title>
										</v-list-item>
									</template>
								</v-list>
							</v-menu>
							<v-divider vertical class="mx-1"></v-divider>
							<v-menu  offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
									color="primary"
									text
									small
									class="ma-1 mr-4"
									v-bind="attrs"
									v-on="on"
									>
										<v-icon left>mdi-filter-variant</v-icon>
										<span class="text-capitalize">显示: {{ status | statusType }}工人</span>
										<v-icon right>mdi-chevron-down</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item
									v-for="(status, index) in userStatus"
									:key="index"
									@click="onStatusChange(status.name)"
									>
									<v-list-item-title>{{ status.text }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
						<v-divider></v-divider>
					</template>
					<template v-if="parseInt(includeWorkTeams) > 0" v-slot:item.workTeams="{item}">
						<template v-for="workTeam in item.workTeams">
							<v-chip label link outlined color="primary" small class="mr-1 mb-1" :to="{ name: 'viewTeam', params: {id:workTeam.id}}">
								{{ workTeam.name }}
							</v-chip>
						</template>
					</template>
					<template v-if="parseInt(includeProjects) > 0" v-slot:item.projects="{item}">
						<template v-for="project in item.projects">
							<v-chip label link outlined color="primary" small class="mr-1 mb-1" :to="{ name: 'viewProject', params: {id:project.id}}">
								{{ project.name }}
							</v-chip>
						</template>
					</template>
					<template v-slot:item.role="{item}">
							{{ item.role | roleType }}
						</template>
					<template v-slot:item.actions="{item}">
						<slot :item="item"></slot>
					</template>
				</v-data-table>
		</template>
	</v-sheet>
</template>
<script>

import UserServices from '../../services/User';
import { mapState } from 'vuex';
import _debounce from 'lodash/debounce';

export default ({
	name: 'userListTab',
	created(){
		this.getUsers();
		if(parseInt(this.includeWorkTeams) > 0){
			this.headers.push(
				{ text: '所属团队', value: 'workTeams', sortable: false, width:200  }
			)
		}
		if(parseInt(this.include_meta) > 0){
			this.headers.push(
				{ text: 'TFN', value: 'meta.tfn' }
			)
		}
		if(parseInt(this.includeProjects) > 0){
			this.headers.push(
				{ text: '项目组', value: 'projects', sortable: false, width:200 }
			)
		}
		this.headers.push(
	        { text: '功能', value: 'actions', sortable: false, width:200  }
		)
	},
	data: () => ({
		users: [],
		search: '',
		itemsPerPage: 25,
		totalPages: 1,
		currentPage: 1,
		items: [5, 10, 15, 25],
		headers: [
			{ text: '编码', value: 'id',align: 'start', width: 80 },
			{ text: '用户名', value: 'name' },
			{ text: '邮箱', value: 'email' },
			{ text: '用户角色', value: 'role' },
		],
		loading: false,
		activeUserId: null,
		activeRole: 'worker,contractor,team-leader,site-manager,admin',
		roles: [
			{ name: 'worker,contractor,team-leader,site-manager,admin', text: '全部工人'},
			{ name: 'worker', text: '工人 (Worker)'},
			{ name: 'contractor', text: '合同工 (Contractor)'},
			{ name: 'team-leader', text: '组长 (Team Leader)'},
			{ name: 'site-manager', text: '工地经理 (Site Manager)'},
			{ name: 'admin', text: '系统管理员 (System Admin)'},
		],
		userStatus: [
			{ name: 0, text: '激活工人'},
			{ name: 1, text: '归档工人'}
		]
	}),
	props: {
		role: {
			type: String,
			default: 'worker'
		},
		status: {
			type: String | Number,
			default: 0
		},
		includeMeta: {
			type: String | Number,
			default: 0,
		},
		includeProjects: {
			type: String | Number,
			default: 0,
		},
		includeWorkTeams: {
			type: String | Number,
			default: 0,
		}
	},
	filters: {
		roleType(value) {
			if (value === 'worker') {
				return 'Worker'
			} else if (value === 'team-leader') {
				return 'Team Leader'
			} else if (value === 'site-manager') {
				return 'Site Manager'
			} else if (value === 'contractor') {
				return 'Contractor'
			} else if (value === 'admin') {
				return '系统管理员'
			} else  {
				return '全部工人';
			}
		},
		statusType(value) {
			if (value == 0) {
				return '激活';
			} else {
				return '归档';
			}
		}
	},
	computed: {
		...mapState('user', ['user'],),
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		},
		role(){
			this.loading = true;
			this.getUsers()
		},
		status(){
			this.loading = true;
			this.getUsers()
		},
	},
	methods: {
		async getUsers(resetRole = false){
			try {
				let roleParam = resetRole ? 'worker,contractor,team-leader,site-manager,admin' : this.role;
				if(resetRole){
					roleParam = 'worker,contractor,team-leader,site-manager,admin';
					this.activeRole = 'worker,contractor,team-leader,site-manager,admin'
				} else {
					roleParam = this.role;
				}

				let params = {
					'page': this.currentPage,
					'per_page': this.itemsPerPage,
					'archived': this.status,
					'roles': roleParam,
					'include_work_teams': this.includeWorkTeams,
					'include_meta': this.includeMeta,
					'include_projects': this.includeProjects
				}

				if(this.search && this.search.length) {
					params.s = this.search;
				}

				let response = await UserServices.getUserList(params)
				this.users = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;
				
				this.loading = false;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
		},
		onUpdatePage(page){
			this.currentPage = page;
		},
		onUserSearch: _debounce( function(){
			this.getUsers();
		}, 600),
		clearUserSearch(){
			this.search = "";
			this.getUsers();
		},
		onRoleChange(roleName){
			this.activeRole = roleName;
			this.$emit('on-filter-role', roleName)
		},
		onStatusChange(statusName) {
			this.$emit('on-filter-status', statusName)
		},
		checkRoleIsUsed(roleName){
			return this.role.includes(roleName)
		}
	}
})
</script>
