import Api from '@/services/Api';

export default {
	getWorkTypeList(isArchived){
		return Api().get(`/work/type?archived=${isArchived}`);
	},
	getWorkType(workerId){
		return Api().get(`/work/type/${workerId}`);
	},
	careateWorkType(body){
		return Api().post(`/work/type`, JSON.stringify(body));
	},
	updateWorkType(workTypeId, body){
		return Api().put(`/work/type/${workTypeId}`, JSON.stringify(body));
	},
	
}