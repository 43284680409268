var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[(_vm.loading)?[_c('v-sheet',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"height":"60vh"}},[_c('v-progress-circular',{attrs:{"size":70,"width":3,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"subtitle-1 mt-4 "},[_vm._v("刷新数据中")])],1)]:[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"page":_vm.currentPage,"footer-props":{
					'items-per-page-text': '每页显示',
					'items-per-page-options': _vm.items
				}},on:{"update:page":function($event){_vm.currentPage=$event},"update:options":_vm.onUpdatePageOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"mx-1 my-2",attrs:{"dense":"","outlined":"","label":"搜索","prepend-inner-icon":"mdi-magnify","clearable":"","clear-icon":"mdi-close","hide-details":"","placeholder":"您可以通过用户名，e-mail和电话号码进行用户搜索"},on:{"input":_vm.onUserSearch,"click:clear":_vm.clearUserSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 mx-1",attrs:{"color":"primary","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-filter-outline")]),_c('span',{staticClass:"text-capitalize"},[_vm._v("显示: "+_vm._s(_vm._f("roleType")(_vm.activeRole)))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.roles),function(role,index){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.onRoleChange(role.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("roleType")(role.name)))])],1)]})],2)],1),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 mr-4",attrs:{"color":"primary","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_c('span',{staticClass:"text-capitalize"},[_vm._v("显示: "+_vm._s(_vm._f("statusType")(_vm.status))+"工人")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.userStatus),function(status,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onStatusChange(status.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(status.text))])],1)}),1)],1)],1),_c('v-divider')]},proxy:true},(parseInt(_vm.includeWorkTeams) > 0)?{key:"item.workTeams",fn:function(ref){
				var item = ref.item;
return [_vm._l((item.workTeams),function(workTeam){return [_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"label":"","link":"","outlined":"","color":"primary","small":"","to":{ name: 'viewTeam', params: {id:workTeam.id}}}},[_vm._v(" "+_vm._s(workTeam.name)+" ")])]})]}}:null,(parseInt(_vm.includeProjects) > 0)?{key:"item.projects",fn:function(ref){
				var item = ref.item;
return [_vm._l((item.projects),function(project){return [_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"label":"","link":"","outlined":"","color":"primary","small":"","to":{ name: 'viewProject', params: {id:project.id}}}},[_vm._v(" "+_vm._s(project.name)+" ")])]})]}}:null,{key:"item.role",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roleType")(item.role))+" ")]}},{key:"item.actions",fn:function(ref){
				var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }