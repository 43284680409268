<template>
	<div>
		<div class="d-flex justify-space-between align-center">
			<v-text-field
				label="搜索团队"
				v-model="search"
				outlined
				dense
				hide-details
				clearable
				clear-icon="mdi-close"
				prepend-inner-icon="mdi-magnify"
				@click:clear="clearWorkTeamSearch"
				@input="onSearchWorkTeam"
				placeholder="输入团队名称"
				class="mx-1 my-2"
			></v-text-field>
			<v-spacer></v-spacer>
			<v-divider vertical class="mx-1"></v-divider>
			<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						color="primary"
						text
						small
						v-bind="attrs"
						class="ma-1 mx-1"
						v-on="on"
						>
						<v-icon small left>
							mdi-filter-variant
						</v-icon>
						显示: {{getStatusText}}
						<v-icon small>
							mdi-menu-down
						</v-icon>
						</v-btn>
					</template>
					<v-list flat dense>
						<v-list-item
							v-for="(item, index) in statusText"
							:key="index"
							@click="updateStatus(item.value)"
							>
						<v-list-item-subtitle class="body-2">{{ item.text }}</v-list-item-subtitle>
						</v-list-item>
					</v-list>
			</v-menu>
		</div>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-data-table
				:headers="headers"
				:items="teams"
				compact
				:items-per-page="itemsPerPage"
				:page.sync="currentPage"
				@update:options="onUpdatePageOptions"
				:footer-props="{
						'items-per-page-text': '每页显示',
						'items-per-page-options': items
					}"
				>
				<template v-slot:item.type="{ item }">
					{{ item.type | getTypeLabel }}
				</template>
				<template v-slot:item.projects="{ item }">
						<template v-for="project in item.projects">
							<v-chip label link outlined color="primary" small class="mr-1 mb-1" :to="{ name: 'viewProject', params: {id:project.id}}">
								{{ project.name }}
							</v-chip>
						</template>
				</template>
				<template v-slot:item.actions="{item}">

					<template v-if="customActions">
						<slot :item="item"></slot>
					</template>
					<template v-else>
						<v-btn 
							color="info"
							elevation="0"
							class="mr-2"
							small
							:to="{ name: 'viewTeam', params: {id:item.id}}"
						>
							<v-icon small>
								mdi-pencil
							</v-icon>
							编辑
						</v-btn>
						<v-btn 
							:color="status ? 'success': 'info'"
							v-if="user && user.role === 'admin'"
							elevation="0"
							text
							small
							@click="updateTeam(item.id)"
						>
						<template v-if="status">
							<v-icon small >
								mdi-archive-arrow-up
							</v-icon>
								激活
						</template>
						<template v-else>
							<v-icon small >
								mdi-archive-arrow-down
							</v-icon>
								归档
						</template>
						</v-btn>
					</template>
				</template>
			</v-data-table>
			<v-divider></v-divider>
		</template>

		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import WorkTeamService from '../../services/WorkTeam';
import WorkTeamForm from '../../components/Works/WorkTeamForm';
import { mapState } from 'vuex';
import _debounce from 'lodash/debounce';

export default {
	name: 'WorkTeamList',
	created(){
		this.getTeams();
		if(parseInt(this.includeProjects) > 0) {
			this.headers.push(
				{ text: '所属项目', value: 'projects', sortable: false, width: 300 },
			)
		}
		this.headers.push(
	        { text: '功能', value: 'actions', sortable: false, width: 200 }
		)
	},
	data: () => ({
		teams: [],
		itemsPerPage: 15,
		totalPages: 1,
		currentPage: 1,
		items: [5, 10, 15, 20],
		status: 0,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '名称', value: 'name' },
			{ text: '组长', value: 'owner.name' },
		],
		teamData: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		loading: false,
		status: 0,
		statusText: [
			{ text: '激活数据', value: 0 },
			{ text: '归档数据', value: 1 },
		],
		search: null
	}),
	components:{
		WorkTeamForm
	},
	props: {
		customActions: {
			type: Boolean,
			default: false
		},
		includeProjects: {
			type: String | Number,
			default: 0,
		},
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	computed: {
		...mapState('user', ['user'],),
		getStatusText(){
			let returnText;
			this.statusText.forEach(status=>{
				if(status.value === this.status){
					returnText = status.text;
				}
			})
			return returnText;
		}
	},
	methods: {
		async getTeams(){
			try {
				let params = {
					"archived": this.status,
					"page": this.currentPage,
					"per_page": this.itemsPerPage,
					"include_projects": this.includeProjects
				};

				if(this.search && this.search.length > 0 ) {
					params.s = this.search;
				}

				let response = await WorkTeamService.getWorkTeamList(params)
				this.teams = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;

			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onUpdatePageOptions(option){
			this.itemsPerPage = option.itemsPerPage;
			this.currentPage = option.page;
		},
		onUpdatePage(page){
			this.currentPage = page;
		},
		async updateTeam(team_id){
			try {
				this.loading = true;
				let body = {
					"archived": this.status ? false : true
				}
				let response = await WorkTeamService.updateWorkTeam(team_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = this.status ? `团队激活成功` : `团队归档成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getTeams();
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.snackbar.model = true;
			}
		},
		updateStatus(event){
			this.status = event;
			this.getTeams();
		},
		clearWorkTeamSearch(){
			this.search = null;
			this.getTeams();
		},
		onSearchWorkTeam: _debounce( function(){
			this.getTeams();
		}, 600),
	}
}
</script>
