<template>
	<v-sheet v-if="workerId != null && accountData != null && user">
			<v-row class="px-3">
				<v-col cols="3" lg="3" sm="3">
					<!--- Default avatar --->
					<div class="d-flex align-center flex-start">
					<template v-if="profile_picture">
						<v-img
							:alt="`${accountData.name}的用户头像`"
							contain
							max-height="80"
							:src="profile_picture"
							transition="scale-transition"
							width="80"
						>
						<template v-slot:placeholder>
								<v-row
								class="fill-height ma-0"
								align="center"
								justify="center"
								>
								<v-progress-circular
									indeterminate
									color="grey lighten-5"
								></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</template>
					<template v-else>
						<v-img
							alt="Empire CBS default avatar"
							contain
							src="@/assets/worker_avatar.png"
							transition="scale-transition"
							width="80"
						/>
					</template>
				</div>
				</v-col>
				<v-col cols="7" lg="7" sm="4">
					<div class="d-flex flex-column">
						<h3>{{ accountData.name }}</h3>
						<div class="subtitle-2 mb-4">{{ getUserRoleText(accountData.role) }}</div>
						<div class="caption mb-1" v-if="emailInput && emailInput.length"><v-icon small left>mdi-email</v-icon>{{ emailInput }}</div>
						<div class="caption mb-1" v-if="accountData.phone && accountData.phone.length"><v-icon small left>mdi-phone</v-icon>{{ accountData.phone }}</div>
						<div class="caption mb-1" v-if="accountData.meta.address && accountData.meta.address.length"><v-icon small left>mdi-home</v-icon>{{ accountData.meta.address }}</div>
					</div>
				</v-col>
				<v-col cols="2" lg="2" sm="4" order="1">

					<RouterLink :to="{ name: 'viewAccount', params: {id:workerId}}">
						<v-icon small left>
							mdi-pencil
						</v-icon>
						编辑
					</RouterLink>
				</v-col>
				</v-row>
				<v-divider class="my-8"></v-divider>
				<v-row class="mx-2">
					<v-col cols="12" lg="3" sm="4">
						<label>出生日期:</label>
						{{ computedDateFormattedDatefns(accountData.date_of_birth) }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>开始日期:</label>
						{{ computedDateFormattedDatefns(accountData.meta.start_date)}}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>结束日期:</label>
						{{ computedDateFormattedDatefns(accountData.meta.end_date)}}
					</v-col>
					<v-col cols="12"  lg="3" sm="4">
						<label>用户备注:</label>
						{{ accountData.meta.notes }}
					</v-col>
				</v-row>
				<!--- 工作信息 --->
				<v-divider class="my-8"></v-divider>
				<v-row class="mx-2">
					<v-col cols="12" lg="3" sm="4">
						<label>TFN(税号)</label>
						{{ accountData.meta.tfn }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>Work Safe:</label>
						{{ accountData.meta.work_safe }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>Cbus:</label>
						{{ accountData.meta.cbus }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>Incolink:</label>
						{{ accountData.meta.incolink }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>Coinvest:</label>
						{{ accountData.meta.coinvest }}
					</v-col>
					<v-col cols="12" lg="3" sm="4">
						<label>Cfmeu:</label>
						{{ accountData.meta.cfmenu }}
					</v-col>
				</v-row>
			<!--- 薪资信息 --->

			<v-divider class="my-8"></v-divider>
				<v-row class="mx-2">
				<v-col cols="12" lg="3" sm="4" v-if="accountData.meta.abn && accountData.meta.abn.length">
					<label>ABN:</label>
					{{ accountData.meta.abn }}
				</v-col>
				<v-col cols="12" lg="3" sm="4" v-if="accountData.meta.payment_method">
					<label>工资支付方式:</label>
					{{ accountData.meta.payment_method }}
				</v-col>
				<template v-if="user && user.role === 'admin'">
					<template v-if=" accountData.meta.payment_method === 'bank_transfer'">
						<v-col cols="12" lg="3" sm="4">
							<label>银行信息:</label>
							{{ accountData.meta.payee_bank_name }}
						</v-col>
						<v-col cols="12" lg="3" sm="4">
							<label>账户名:</label>
							{{ accountData.meta.payee_account_name }}
						</v-col>
						<v-col cols="12" lg="3" sm="4">
							<label>BSB和账号:</label>
							{{ accountData.meta.payee_bsb }} - {{ accountData.meta.payee_account_number }}
						</v-col>
					</template>
				</template>
			</v-row>
			<v-divider class="mt-8 mb-4"></v-divider>
			<!--- Main Area End --->
			<template v-if="workerId">
				<user-team-tab :userId="workerId" :team="accountData.workTeams" class="mt-6" />
				<!-- <user-payment-tab :userId="workerId" class="mt-6" /> -->
			</template>
	</v-sheet>
</template>

<script>

import { mapState } from 'vuex';
import UserServices from '../../services/User';

import {  parse, format } from 'date-fns';
import UserPaymentTab from '../../components/User/UserPaymentTab.vue';
import UserTeamTab from '../../components/User/UserTeamTab.vue';

export default {
	name: 'accountPreview',
	components: {
		UserTeamTab,
	},
	props: {
		workerId: {
			type: Number || String,
			required: true
		}
	},
	created(){
		this.getAccountDetail();
	},
	data: () => ({
		valid: true,
		paymentMethod: [
			{ text: 'Bank Transfer', value: 'bank_transfer' },
			{ text: 'Cash', value: 'cash'}
		],
		accountData: {
			name: '',
			phone: '',
			role: '',
			date_of_birth: '',
			archived: '',
			allow_login: false,
			meta:{
				address: null,
				tfn: null,
				work_safe: null,
				cbus: null,
				incolink: null,
				coinvest: null,
				cfmenu: null,
				start_date: null,
				end_date: null,
				notes: null,
				payment_method: null,
				abn: '',
				payee_bank_name: '',
				payee_bsb: '',
				payee_account_name: '',
				payee_account_number: '',
			}
		},
		emailInput: null,
		currentEmail: null,
		compliance_cards: null,
		profile_picture: null,
		loading: false,

		roles: [
			{ text: 'Administrator （系统管理员）', value: 'admin'},
			{ text: 'Site Manager (工地经理)', value: 'site-manager'},
			{ text: 'Team Leader (组长)', value: 'team-leader'},
			{ text: 'Worker（工人）', value: 'worker'},
			{ text: 'Contractor（合同工）', value: 'contractor'},
		],
		password: '',
		updatePassword: false,
		showpassword: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		profilePictureChanged: false,
		complianceFormChanged: false,
		overlay: false,
	}),
	watch: {

	},
	computed: {
		...mapState('user', ['user'],),
		accountStatus(){
			return this.accountData.archived ? '未激活' : '激活';
		},
	},
	methods: {
		formatDate (date) {
				if (!date) return null
				if (date.includes('-')){
					const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
				}
				if (date.includes('/')){
					return date
				}
		},
		computedDateFormattedDatefns (date) {
			return date ? this.formatDate(date) : ''
		},
		async getAccountDetail(){
			try {
				let response = await UserServices.getUserById(this.workerId);
				if(response.data.statusCode === 200){
					const user = response.data.data;

					this.accountData.allow_login = user.allow_login;
					this.accountData.archived = user.archived;
					this.accountData.date_of_birth = user.date_of_birth ? format(parse(user.date_of_birth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'): null;

					this.currentEmail = user.email;
					this.emailInput = this.currentEmail;

					this.accountData.name = user.name;
					this.accountData.phone = user.phone;
					this.accountData.role = user.role;
					//map all the meta field.
					this.accountData.meta.abn = user.meta.abn;
					this.accountData.meta.address = user.meta.address;
					this.accountData.meta.cbus = user.meta.cbus;
					this.accountData.meta.cfmenu = user.meta.cfmenu;
					this.accountData.meta.coinvest = user.meta.coinvest;
					this.accountData.meta.cbus = user.meta.cbus;
					this.accountData.meta.end_date = user.meta.end_date ? format(parse(user.meta.end_date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null;
					this.accountData.meta.incolink = user.meta.incolink;
					this.accountData.meta.notes = user.meta.notes;
					this.accountData.meta.payee_account_name = user.meta.payee_account_name;
					this.accountData.meta.payee_account_number = user.meta.payee_account_number;
					this.accountData.meta.payee_bank_name = user.meta.payee_bank_name;
					this.accountData.meta.payee_bsb = user.meta.payee_bsb;
					this.accountData.meta.payment_method = user.meta.payment_method ? user.meta.payment_method : null;
					this.accountData.meta.start_date = user.meta.start_date ? format(parse(user.meta.start_date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null;
					this.accountData.meta.tfn = user.meta.tfn;
					this.accountData.meta.work_safe = user.meta.work_safe;
					
					this.accountData.workTeams = user.workTeams;
					this.profile_picture =  user.meta.profile_picture && user.meta.profile_picture.length > 0 ? user.meta.profile_picture : null ;
					this.compliance_cards = user.meta.compliance_cards && user.meta.compliance_cards.length > 0 ? user.meta.compliance_cards : null ;
				}
			} catch(error) {	
				this.popToast(`获取用户信息错误${error.data.message}`, 'error');
			}
		},
		getUserRoleText(roleName){
			const matchRole =  this.roles.filter((role)=> role.value === roleName);
			return matchRole.length ? matchRole[0].text : '';
		},
	}
}
</script>
<style scoped>
	label {
		display: block;
		font-weight: 600;
		opacity: 0.85;
	}
</style>