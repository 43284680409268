<template>
	<v-sheet>
		<v-toolbar flat>
			<v-toolbar-title>团队管理</v-toolbar-title>
			<v-spacer></v-spacer>
			<template v-if="tab === 0">
				<v-btn 
					v-if="user && user.role === 'admin'"
					color="primary"
					@click="dialogWorkTeam = true"
					elevation="0">
					<v-icon>mdi-plus</v-icon> 添加团队
				</v-btn>
			</template>
			<template v-if="tab === 1">
				<v-btn 
				v-if="user && user.role === 'admin'"
				color="primary"
				:to="{ name: 'createAccount', params: {role: 'worker'}}"
				elevation="0">
				<v-icon>mdi-plus</v-icon> 创建工人
			</v-btn>
			</template>
			<template v-if="tab === 2">
				<v-btn 
					v-if="user && user.role === 'admin'"
					color="primary"
					@click="dialogWorkType = true"
					elevation="0">
					<v-icon>mdi-plus</v-icon> 添加工种
				</v-btn>
			</template>
		</v-toolbar>
		<v-divider></v-divider>
		<v-tabs v-model="tab" @change="loading = true">
			<v-tab v-for="tab in items" :key="tab.name">{{tab.name}}</v-tab>
		</v-tabs>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<template v-if="tab === 0">
				<work-team-list :includeProjects="1" />
				<v-dialog v-model="dialogWorkTeam"
					max-width="480">
					<work-team-form 
					@on-submit-success="onWorkTeamCreated" />
				</v-dialog>
			</template>
			<template v-if="tab === 1">
				<account-list />
			</template>
			<template v-if="tab === 2">
				<work-type-list />
				<!--- create work type Dialog ---->
				<v-dialog v-model="dialogWorkType" 
					max-width="480">
						<work-type-form 
						 @on-submit-success="onWorkTypeCreated" />
				</v-dialog>
			</template>
		</template>
	</v-sheet>
</template>

<script>
import workTeamList from '../../components/Works/WorkTeamList';
import WorkTypeList from '../../components/Works/WorkTypeList';
import WorkTypeForm from '../../components/Works/WorkTypeForm';
import WorkTeamForm from '../../components/Works/WorkTeamForm';
import AccountList from '../account/account_list.vue';

import { mapState } from 'vuex';
export default {
	name: 'Team',
	components: {
		workTeamList,
		WorkTypeList,
		WorkTypeForm,
		WorkTeamForm,
		AccountList
	},
	computed: {
		...mapState('user', ['user'],),
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		},
		tab(){
			const url = new URL(window.location)
	 	 	url.searchParams.set("tab", this.tab)
  			history.pushState(null, '', url);
		}
	},
	data: () => ({
		tab: 0,
		currentUser: null,
		items: [
			{'name': '工作团队' },
			{'name': '工人管理' },
			{'name': '工种管理' },
		],
		dialogWorker: false,
		dialogWorkType: false,
		dialogWorkTeam: false,
		loading: false
	}),
	methods: {
		onWorkTypeCreated(){
			this.dialogWorkType = false;
			this.loading = true;
		},
		onWorkerCreated(){
			this.dialogWorker = false;
			this.loading = true;
		},
		onWorkTeamCreated(){
			this.dialogWorkTeam = false;
			this.loading = true;
		}
	},
	created(){
		let urlParams = new URLSearchParams(window.location.search);
		if(urlParams.has('tab')) {
			this.tab = parseInt(urlParams.get('tab'));
		}
		this.loading = true;
	}
}
</script>
