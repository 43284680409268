<template>
		<div>
		<div class="d-flex justify-space-between align-center  px-4">
			<v-text-field
				label="搜索工种"
				v-model="search"
				outlined
				dense
				hide-details
				clearable
				clear-icon="mdi-close"
				prepend-inner-icon="mdi-magnify"
				@click:clear="clearWorkTypeSearch"
				@input="onSearchWorkType"
				placeholder="输入工种名称"
				class="mx-1 my-2"
			></v-text-field>
			<v-spacer></v-spacer>
			<v-divider vertical class="mx-1"></v-divider>
				<!--- worker filter --->
				<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							text
							small
							tile
							v-bind="attrs"
							v-on="on"
							class="ml-2"
							>
							<v-icon small left>
								mdi-filter-variant
							</v-icon>
							显示: {{getStatusText}}
							<v-icon small>
								mdi-menu-down
							</v-icon>
							</v-btn>
						</template>
						<v-list flat dense>
							<v-list-item
								v-for="(item, index) in statusText"
								:key="index"
								@click="updateStatus(item.value)"
								>
							<v-list-item-subtitle class="body-2">{{ item.text }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
				</v-menu>
		</div>
		<v-divider></v-divider>		
		<v-data-table
			:headers="headers"
			:items="workTypes"
			:items-per-page="workTypes.length"
			hide-default-footer
			>
			<template v-slot:item.actions="{item}">
				<v-btn 
					color="primary"
					elevation="0"
					class="mr-2"
					small
					v-if="user && user.role === 'admin'"
					@click="viewWorkType(item.id)"
				>
					<v-icon small left>
						mdi-pencil
					</v-icon>
					编辑信息
				</v-btn>
				<v-btn 
						:color="archived ? 'success': 'info'"
						elevation="0"
						class="mr-2"
						small
						v-if="user && user.role === 'admin'"
						@click="updateWorkTypeStatus(item)"
					>
						<template v-if="archived">
							<v-icon small left>
								mdi-archive-arrow-up
							</v-icon>
								激活信息
						</template>
						<template v-else>
							<v-icon small left>
								mdi-archive-arrow-down
							</v-icon>
								归档信息
						</template>
				</v-btn>

			</template>
		</v-data-table>
		<v-divider></v-divider>	
		<v-dialog v-model="dialogEditWorkType" max-width="360">
			<work-type-form 
					:workType="workTypeData"
					@on-submit-success="onWorkTypeFormSubmit"
				/>
		</v-dialog>

		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import WorkTypeService from '../../services/WorkType';
import WorkTypeForm from '../Works/WorkTypeForm';
import { mapState } from 'vuex';

export default {
	name: 'WorkTypeList',
	components: {
		WorkTypeForm
	},
	data: () => ({
		workTypes: [],
		archived: 0,
		headers: [
			{ text: '编码', value: 'id',align: 'start', },
			{ text: '名称', value: 'name' },
	        { text: '功能', value: 'actions', sortable: false, width: 260 }
		],
		dialogEditWorkType: false,
		workTypeData: null,
		statusText: [
			{ text: '激活数据', value: 0 },
			{ text: '归档数据', value: 1 },
		],
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		search: null
	}),
	props: {
	},
	watch: {
		search(){
			if(this.search === null || this.search.length === 0){
				this.getWorkTypes();
			}
		}
	},
	computed: {
		...mapState('user', ['user'],),
		getStatusText(){
			let returnText;
			this.statusText.forEach(status=>{
				if(status.value === this.archived){
					returnText = status.text;
				}
			})
			return returnText;
		}
	},
	created(){
		this.getWorkTypes();
	},
	methods: {
		async getWorkTypes(){
			try {
				let response = await WorkTypeService.getWorkTypeList(this.archived)
				this.workTypes = response.data.data;
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async viewWorkType(type_id){
			this.workTypeData = null;
			try {
				let response = await WorkTypeService.getWorkType(type_id);
				this.workTypeData = response.data.data;
				this.dialogEditWorkType = true;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async updateWorkTypeStatus(type){
			const body = {
				"name": type.name,
				"archived": this.archived ? false : true
			}
			try {
				let response = await WorkTypeService.updateWorkType(type.id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = this.archived ? `激活成功` : `归档成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getWorkTypes();
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.snackbar.model = true;
			}
		},
		onWorkTypeFormSubmit(){
			this.dialogEditWorkType = false;
			this.workTypeData = null;
			this.getWorkTypes();
		},
		updateStatus(event){
			this.archived = event;
			this.getWorkTypes();
		},
		clearWorkTypeSearch(){
			this.search = null
			this.getWorkTypes();
		},
		onSearchWorkType(){
			const searchResult = this.workTypes.filter((workType)=>{
				return workType.name.includes(this.search);
			})
			this.workTypes = searchResult;
		}
	}
}
</script>
